<template>
  <div
    v-if="visible"
    id="modal-template"
    class="lightbox"
    transition="modal"
    @hide="$emit('hide', {type: 'lightbox'})"
  >
    <div class="modal-mask">
      <div class="modal-wrapper">
        <!-- <div class="modal-container" :style="{width: modalwidth, height: modalheight}"> -->
        <!-- <div class="modal-container" :style="{width: modalwidth}"> -->
        <div class="modal-container">
          <div class="modal-header">
            <slot name="header">
              <a class="modal-default-button" @click="$emit('hide', {type: 'lightbox'})"
                ><i class="fa fa-times-circle fa-2x" aria-hidden="true"></i
              ></a>
            </slot>
          </div>

          <div class="modal-body" align="center">
            <img :src="imgsrc" :height="maxheight" :alt="alt" />
            <p>{{ caption }}</p>
          </div>

          <div class="modal-footer">
            <slot name="footer">
              <!-- <button class="modal-default-button" @click="close()">close</button> -->
            </slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
    }
  },
  props: {
    modalheight: { type: String, default: '90%' },
    modalwidth: String,
    maxheight: String,
    imgsrc: String,
    imgheight: String,
    imgwidth: String,
    alt: String,
    caption: String,
    visible: {
      type: Boolean,
      default: false
    }
  },
}
</script>

<style type="text/css" media="screen">
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  position: fixed;
  /*    width: 300px;*/
  /*    margin: 0px auto;*/
  /*    padding: 20px 30px;*/
  padding: 1rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  /*    transition: all .3s ease;*/
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}

/*
   * The following styles are auto-applied to elements with
   * transition="modal" when their visibility is toggled
   * by Vue.js.
   *
   * You can easily play with the modal transition by editing
   * these styles.
   */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
